import perfilfoto from "./perfilfoto.jpeg";
import projects from "./projetosLista";
import logotipoUMa from "./logotipoUMa.png";
import Navbar from "../Navbar/Navbar";
import React from "react";
function Home() {
  return (
    <>
      <header className="fixed z-10 right-0 left-0 min-h-[50px]">
        <Navbar />
      </header>
      <div className="container mt-32 mx-auto">
        <div className="flex justify-center">
          <img
            className="rounded-full h-32 w-32"
            alt="Foto perfil"
            src={perfilfoto}
          />
        </div>
        <div>
          <h1 className="text-center text-6xl mt-5">Paulo Alves</h1>
          <h2 className="text-center text-3xl mt-5">
            Student, Developer and Ex-Swimmer
          </h2>
        </div>
        <div className="dark:bg-[#2E333A] mr-10 ml-10 mt-4 p-4 rounded-md">
          <h3 className="text-3xl pt-2">About me</h3>
          <p className="text-xl mt-5">
            I&apos;m a student of computer science and engineering at Universidade da Madeira (UMa).
            I&apos;m a developer and I&apos;m always looking for new challenges
            and learning new things. I used to be a swimmer, but now I enjoy
            swimming as a hobby, going to the beach and enjoy go the caffe with my friends.
          </p>
        </div>
        <div>
          <h2 className="text-center text-5xl mt-10">Portfolio</h2>
        </div>
        <div className="dark:bg-[#2E333A] mr-10 ml-10 mt-4 p-4 rounded-md">
          <h3 className="text-3xl pt-2">Projects</h3>
          <p className="text-xl mt-5">
            I&apos;m currently working on a project that will help people to
            find the best places to swim in Madeira. I&apos;m also working on a
            project that will help people to find the best places to eat in
            Madeira.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-10 mb-10 mr-10 ml-10 gap-5">
          {projects.map((project, index) => (
            <div
              key={index}
              className="card w-auto h-auto bg-slate-500 text-primary-content"
            >
              <div className="card-body">
                <h2 className="card-title justify-center text-lg ml-2 mr-2">
                  {project.title}
                </h2>
                <h4 className="justify-between ml-2 mr-2">
                  {project.description}
                </h4>
                <a
                  href={project.website}
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-neutral mt-auto"
                >
                  See project
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10">
          <h2 className="text-center text-5xl">Timeline</h2>
          <ul className="mt-10 timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
            <li>
              <div className="timeline-middle ml-2 ">
                <img
                  className="w-14 h-14 rounded-full"
                  alt="UMa"
                  src={logotipoUMa}
                />
              </div>
              <div className="timeline-start md:text-end mb-10 mt-5">
                <time className="font-mono italic">September 2022</time>
                <div className="mt-4 ml-5 mr-5 mb-10 card w-auto  bg-[#2E333A] text-white">
                  <div className="card-body flex flex-col items-center">
                    <h2 className="text-2xl font-medium">
                      Universidade da Madeira
                    </h2>
                    <h4 className="text-xl font-medium">
                      Computer Science Degree
                    </h4>
                    <h4 className="text-base font-medium">
                      I started university at{" "}
                      <a
                        className="text-blue-500"
                        rel="noreferrer"
                        href="https://uma.pt/"
                        target="_blank"
                      >
                        Universidade da Madeira
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home;
