import Navbar from "../Navbar/Navbar";
import React from "react";
//TODO Make in a js file for just needed to import and map
export default function ImpossibleList() {
  return (
    <>
      <header className="fixed z-10 right-0 left-0 min-h-[50px]">
        <Navbar />
      </header>
      <div className="ml-5 mr-5 container mx-auto mt-32">
        <h1 className="text-center text-5xl mb-10 ">Impossible List</h1>
        <div className="mb-10">
          <h2 className="text-4xl mb-5 ml-2">Technology/Academy Goals</h2>
          <ul className="list-disc ml-10 mr-2 text-xl">
            <li>Degree in computer Science and engineering</li>
            <li>Build a mobile app</li>
            <li>Learn artificial intelligence and machine learning</li>
            <li>Contribute to an open-source project</li>
            <li>
              <s>Develop a personal website</s>
            </li>
          </ul>
        </div>
        <div className="mb-10">
          <h2 className="text-4xl mb-5 ml-2">Health/Sport Goals</h2>
          <ul className="list-disc ml-10 mr-2 text-xl">
            <li>Run a marathon</li>
            <li>Complete a triathlon</li>
            <li>
              <s>Complete a duathlon</s>
            </li>
            <li>
              <s>Open water event-5 kilometers</s>
            </li>
            <li>
              <s>Swimming national record-2018 (14 years old)</s>
            </li>
            <li>
              <s>Swimming national 3x champion-2019 (15 years old)</s>
            </li>
          </ul>
        </div>
        <div className="mb-10">
          <h2 className="text-4xl mb-5 ml-2">Traveling</h2>
          <ul className="list-disc ml-10 mr-2 text-xl">
            <li>Visit all EU countries</li>
            <li>See the Northern Lights</li>
            <li>Go on a safari in Africa</li>
          </ul>
        </div>
        <div className="mb-10">
          <h2 className="text-4xl mb-5 ml-2">Events to Attend</h2>
          <ul className="list-disc ml-10 mr-2 text-xl">
            <li>Attend the Olympics</li>
            <li>Go to a World Cup final</li>
            <li>Attend a Champions game</li>
            <li>
              <s>Attend a big music festival</s>
            </li>
          </ul>
        </div>
        <div className="mb-10">
          <h2 className="text-4xl mb-5 ml-2">Life Goals and Misc</h2>
          <ul className="list-disc ml-10 mr-2 text-xl">
            <li>Travel to all 7 continents</li>
            <li>Learn german</li>
            <li>Get better at speaking French</li>
            <li>Own a home</li>
            <li>Go on Erasmus</li>
            <li>
              <s>Learn to play the guitar</s>
            </li>
            <li>
              <s>Volunteering at Academica da Madeira</s>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
